import {
    get,
    post
  }
    from '../common/axiosHttp.js'

// 获取设备列表
export const getStorageAll = params => get('/storage/getStorageAll', params);

// 获取设备轨迹
export const getStorageTrajectory = params => get('/storage/getStorageTrajectory', params);

